<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pa-3>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <span class="itemHeading1"
              >Edit Resolve Details
              <span v-if="animalname && locationnameofincident"
                >: {{ animalname }},{{ locationnameofincident }}({{
                  formattedReportedDate
                }})</span
              ></span
            >
          </v-flex>
          <!-- <v-flex xs6 lg2 md4 sm5 align-self-start>
              <v-divider></v-divider>
            </v-flex> -->
        </v-layout>
        <v-layout wrap justify-center pt-3>
          <v-flex xs12>
            <v-layout wrap justify-start pb-2>
              <v-flex xs12>
                <span class="SubitemHeading1">Primary Details</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-text-field
                  outlined
                  append-icon="mdi-magnify"
                  append-outer-icon="mdi-close"
                  label="Location of actual incident"
                  placeholder="Enter Location Name"
                  v-model="locationname"
                  class="itemform"
                  dense
                  @click:append="plotLocation"
                  @click:append-outer="resetFields"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <!-- <v-flex xs12 md4 sm4 lg4>
                  <v-text-field
                    outlined
                    label="Location of actual incident"
                    placeholder="Enter Location Name"
                    v-model="locationname"
                    class="itemform"
                    dense
                  ></v-text-field>
                </v-flex> -->
              <v-flex xs12 md3 sm3 lg3>
                <v-text-field
                  outlined
                  label="Latitude"
                  v-model="lattitude"
                  type="number"
                  disabled
                  class="inputPrice itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-text-field
                  outlined
                  label="Longitude"
                  v-model="longitude"
                  disabled
                  type="number"
                  class="inputPrice itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      label="Time of actual incident"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="itemform"
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu2"
                    v-model="time"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Date of actual incident"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="itemform"
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 md6 sm6 lg6>
                <v-select
                  :items="animalTypes"
                  label="Type Of Animal"
                  outlined
                  item-text="name"
                  item-value="_id"
                  v-model="animaltype"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 md6 sm6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                <v-select
                  :items="sightingorconflict"
                  label="Sighting/ Conflict"
                  outlined
                  item-text="name"
                  item-value="name"
                  v-model="sightingorconflicttype"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pb-2>
              <v-flex xs12 align-self-center>
                <span class="SubitemHeading1">Animal Details</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 sm6 md6 lg6>
                <v-select
                  :items="insideforesttypes"
                  label="Whether inside forest?"
                  outlined
                  v-model="insideforest"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-md-2
                pl-sm-2
                v-if="insideforest === 'Yes'"
              >
                <v-text-field
                  outlined
                  label="RF/PA Name"
                  placeholder="Enter RF/PA Name"
                  v-model="reservedorprotectedname"
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-md-2
                pl-sm-2
                v-if="insideforest === 'No'"
              >
                <v-text-field
                  outlined
                  label="Name of Nearest RF/PA"
                  placeholder="Enter Name of Nearest RF/PA"
                  v-model="reservedorprotectedname"
                  class="itemform"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 sm6 md6 lg6>
                <v-select
                  :items="animalpresenttype"
                  label="Whether the animal was present when the rescue team reached?"
                  outlined
                  v-model="animalpresent"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-md-2
                pl-sm-2
                v-if="animalpresent === 'Yes'"
              >
                <v-select
                  :items="numofanimalslist"
                  label="Number of Animals"
                  outlined
                  v-model="editresolve.singleOrGroup"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-md-2
                pl-sm-2
                v-if="animalpresent === 'No'"
              >
                <v-select
                  :items="numofanimalslist"
                  label="No of animals as per local people"
                  outlined
                  v-model="editresolve.singleOrGroup"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              v-if="
                editresolve.singleOrGroup === 'Single' ||
                editresolve.singleOrGroup === 'Single'
              "
            >
              <v-flex xs12 sm4 md4 lg4>
                <v-select
                  :items="formattedAgeOfAnimal"
                  label="Age of Animal"
                  outlined
                  item-text="category"
                  item-value="category"
                  v-model="singleDetails.animalAge"
                  class="itemform"
                  dense
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                <v-select
                  :items="statusofanimal"
                  label="Status of animal"
                  item-text="name"
                  item-value="_id"
                  outlined
                  v-model="singleDetails.animalStatusId"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                <v-select
                  :items="genderofanimal"
                  label="Gender of Animal"
                  item-text="name"
                  item-value="_id"
                  outlined
                  v-model="singleDetails.genderId"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              v-if="
                editresolve.singleOrGroup === 'Group' ||
                editresolve.singleOrGroup === 'Group'
              "
            >
              <!-- If number of animals is group then the count based on age -->
              <v-flex xs12 sm3 md3 lg3>
                <v-text-field
                  outlined
                  label="Adult Animals"
                  placeholder="Enter No of Adult Animals"
                  v-model="groupDetails.adultCount"
                  type="number"
                  class="inputPrice itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-text-field
                  outlined
                  label="SubAdult Animals"
                  placeholder="Enter No of SubAdult Animals"
                  v-model="groupDetails.subAdultCount"
                  type="number"
                  class="inputPrice itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-text-field
                  outlined
                  label="Juvenile Animals"
                  placeholder="Enter No of Juvenile Animals"
                  v-model="groupDetails.juvenileCount"
                  type="number"
                  class="inputPrice itemform"
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-text-field
                  outlined
                  label="Calf Animals"
                  placeholder="Enter No of Calf Animals"
                  v-model="groupDetails.calfCount"
                  type="number"
                  class="inputPrice itemform"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              v-if="
                editresolve.singleOrGroup === 'Group' ||
                editresolve.singleOrGroup === 'Group'
              "
            >
              <v-flex xs12 sm4 md4 lg4>
                <v-text-field
                  outlined
                  label="Male Animal"
                  placeholder="Enter No of Male Animals"
                  type="number"
                  class="inputPrice itemform"
                  dense
                  v-model="groupDetails.maleCount"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                <v-text-field
                  outlined
                  label="Female Animal"
                  placeholder="Enter No of Female Animals"
                  type="number"
                  class="inputPrice itemform"
                  dense
                  v-model="groupDetails.femaleCount"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                <v-text-field
                  outlined
                  label="Cannot be determined Animal"
                  placeholder="Cannot be determined Animals"
                  type="number"
                  class="inputPrice itemform"
                  dense
                  v-model="groupDetails.cannotDetermineCount"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              v-if="
                editresolve.singleOrGroup === 'Group' ||
                editresolve.singleOrGroup === 'Group'
              "
            >
              <v-flex xs12 sm12 md12 lg12>
                <v-select
                  :items="statusofanimalgrouptypes"
                  label="Status of animal"
                  outlined
                  v-model="groupDetails.animalStatus"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pb-2>
              <v-flex xs12 align-self-center>
                <span class="SubitemHeading1">Conflict Details</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 sm6 md6 lg6>
                <v-select
                  :items="ActivityofAnimals"
                  label="Activity of Animals"
                  outlined
                  item-text="name"
                  item-value="_id"
                  v-model="animalactivity"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                <v-select
                  :items="ConflictTypes"
                  label="Type of Conflict "
                  outlined
                  item-text="name"
                  item-value="_id"
                  multiple
                  v-model="ConflictType"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="isCropDamageSelected" pb-2>
              <v-flex xs12 align-self-center>
                <span class="SubitemHeading2">Crop Details</span>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-layout
                  wrap
                  justify-center
                  v-for="(step, index) in cropTypedetails"
                  :key="index"
                >
                  <v-flex xs12 sm3 md3 lg3>
                    <v-select
                      :items="Typeofcrops"
                      label="Type of crop"
                      outlined
                      item-text="name"
                      item-value="_id"
                      v-model="step.cropId"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-text-field
                      outlined
                      label="Extent or number damaged"
                      placeholder="Enter Extent or number damaged"
                      v-model="step.count"
                      class="itemform"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-select
                      :items="Unitsofcrops"
                      label="Units"
                      outlined
                      v-model="step.unit"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2 pt-1>
                    <span v-if="cropTypedetails.length > 0">
                      <v-btn
                        small
                        plain
                        @click="removecropTypedetails(index)"
                        color="error"
                        class="itemform"
                        dense
                      >
                        <span>Remove</span
                        ><span><v-icon small>mdi-delete</v-icon></span>
                      </v-btn>
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-end>
                  <v-flex xs12 sm3 md3 lg3>
                    <v-btn
                      small
                      plain
                      @click="addcropTypedetails"
                      color="primary"
                    >
                      <span><v-icon small>mdi-plus</v-icon></span
                      ><span>ADD More</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="isPropertyDamageSelected">
              <v-flex xs12 align-self-center>
                <span class="SubitemHeading2">Property Details</span>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-layout
                  wrap
                  justify-center
                  v-for="(step, index) in propertyTypedetails"
                  :key="index"
                >
                  <v-flex xs12 sm4 md4 lg4>
                    <v-text-field
                      outlined
                      label="Type of property"
                      placeholder="Enter Type of property"
                      v-model="step.propertyType"
                      class="itemform"
                      dense
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="Details of damage"
                      v-model="step.damageDetail"
                      class="itemform"
                      dense
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2 pt-1>
                    <span v-if="propertyTypedetails.length > 0">
                      <v-btn
                        small
                        plain
                        @click="removepropertyTypedetails(index)"
                        color="error"
                        class="itemform"
                        dense
                      >
                        <span>Remove</span
                        ><span><v-icon small>mdi-delete</v-icon></span>
                      </v-btn>
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-end>
                  <v-flex xs12 sm3 md3 lg3>
                    <v-btn
                      small
                      plain
                      @click="addpropertyTypedetails"
                      color="primary"
                    >
                      <span><v-icon small>mdi-plus</v-icon></span
                      ><span>ADD More</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="isHumanInjurySelected">
              <v-flex xs12 align-self-center pb-1>
                <span class="SubitemHeading2">Injured People Details</span>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-text-field
                  outlined
                  label="Number of people Injured"
                  placeholder="Enter Number of people Injured"
                  v-model="nopeopleinjured"
                  type="number"
                  class="inputPrice itemform"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              v-if="isHumanInjurySelected && injuredPeople.length > 0"
            >
              <v-flex xs12>
                <v-layout
                  wrap
                  justify-center
                  v-for="(person, index) in injuredPeople"
                  :key="index"
                >
                  <v-flex xs12 sm1 md1 lg1 class="text-center" pt-4>
                    <span>{{ index + 1 }}.</span>
                  </v-flex>
                  <v-flex xs12 sm2 md2 lg2 pl-lg-2 pl-md-2 pl-sm-2>
                    <!-- <v-select
                        :items="Typeofinjury"
                        label="Type of injury"
                        outlined
                        v-model="person.injurytype"
                        class="itemform"
                        dense
                      ></v-select> -->
                    <v-text-field
                      outlined
                      label="Type of injury"
                      placeholder="Enter Type of injury"
                      v-model="person.injurytype"
                      class="itemform"
                      dense
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-text-field
                      outlined
                      label="Name of the person"
                      placeholder="Enter Name of the person"
                      v-model="person.nameofpersoninjured"
                      class="itemform"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm1 md1 lg1 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-text-field
                      outlined
                      label="Age"
                      placeholder="Age"
                      v-model="person.ageofpersoninjured"
                      type="number"
                      class="inputPrice itemform"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm2 md2 lg2 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-select
                      :items="persongender"
                      label="Gender"
                      outlined
                      v-model="person.genderofpersoninjured"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-select
                      :items="WhetherHospitalized"
                      label="Whether Hospitalized?"
                      outlined
                      v-model="person.hospitalizedinjured"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="isHumanDeathSelected">
              <v-flex xs12 align-self-center pb-1>
                <span class="SubitemHeading2">Details Of People Died</span>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-text-field
                  outlined
                  label="Number of people Died"
                  placeholder="Enter Number of people Died"
                  v-model="nopeopledied"
                  type="number"
                  class="inputPrice itemform"
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              v-if="isHumanDeathSelected && peopleDead.length > 0"
            >
              <v-flex xs12>
                <v-layout
                  wrap
                  justify-center
                  v-for="(person, index) in peopleDead"
                  :key="index"
                >
                  <v-flex xs12 sm1 md1 lg1 class="text-center" pt-4>
                    <span>{{ index + 1 }}.</span>
                  </v-flex>
                  <v-flex xs12 sm5 md5 lg5>
                    <v-text-field
                      outlined
                      label="Name of the person"
                      placeholder="Enter Name"
                      v-model="person.namepersondied"
                      class="itemform"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-text-field
                      outlined
                      label="Age"
                      placeholder="Enter Age"
                      v-model="person.agepersondied"
                      type="number"
                      class="inputPrice itemform"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-select
                      :items="persongender"
                      label="Gender"
                      outlined
                      v-model="person.genderofpersondied"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="isAnimalInjurySelected">
              <v-flex xs12 align-self-center pb-1>
                <span class="SubitemHeading2">Details Of Animal Injured</span>
              </v-flex>
              <v-flex xs12 sm6 md6 lg6 pt-2>
                <v-text-field
                  outlined
                  label="Number of animals injured"
                  placeholder="Enter Number of animals injured"
                  type="number"
                  class="inputPrice itemform"
                  dense
                  v-model="animalInjuryDetails.injuredNumber"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6 lg6 pl-lg-2 pl-md-2 pl-sm-2 pt-2>
                <v-text-field
                  outlined
                  label="Type of injury"
                  placeholder="Enter Type of injury"
                  v-model="animalInjuryDetails.typeOfInjury"
                  class="itemform"
                  dense
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="isAnimalDeathSelected">
              <v-flex xs12 align-self-center pb-1>
                <span class="SubitemHeading2">Details Of Animal Died</span>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-text-field
                  outlined
                  label="Number of animals died"
                  placeholder="Enter Number of animals died"
                  type="number"
                  class="inputPrice itemform"
                  dense
                  v-model="animalDeathDetails.numberOfDied"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-if="isLivestockDepredationSelected">
              <v-flex xs12 align-self-center pb-1>
                <span class="SubitemHeading2"
                  >Details Of Livestock Depredation</span
                >
              </v-flex>
              <!-- <v-flex xs12>
                <v-text-field
                  outlined
                  label="Number of animals"
                  placeholder="Enter Number of animals"
                  type="number"
                  class="inputPrice itemform"
                  dense
                  v-model="numberoflivestockdepredationanimal"
                ></v-text-field>
              </v-flex> -->
            </v-layout>
            <v-layout wrap justify-center v-if="isLivestockDepredationSelected">
              <v-flex xs12 pt-2>
                <v-layout
                  wrap
                  justify-center
                  v-for="(person, index) in livestockanimal"
                  :key="index"
                >
                  <v-flex xs12 sm5 md5 lg3>
                    <v-select
                      :items="depredationType"
                      label="Type Of Depredation"
                      outlined
                      v-model="person.injuryOrDeath"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-text-field
                      outlined
                      label="Type Of Animal"
                      placeholder="Enter Type Of Animal"
                      v-model="person.typeOfAnimal"
                      class="itemform"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-text-field
                      outlined
                      label="Number Of Animals"
                      placeholder="Enter Number Of Animals"
                      v-model="person.numberOfAnimals"
                      class="itemform inputPrice"
                      dense
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2 pt-1>
                    <span v-if="livestockanimal.length > 0">
                      <v-btn
                        small
                        plain
                        @click="removelivestockanimaldetails(index)"
                        color="error"
                        class="itemform"
                        dense
                      >
                        <span>Remove</span
                        ><span><v-icon small>mdi-delete</v-icon></span>
                      </v-btn>
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-end>
                  <v-flex xs12 sm3 md3 lg3>
                    <v-btn
                      small
                      plain
                      @click="addlivestockanimaldetails"
                      color="primary"
                    >
                      <span><v-icon small>mdi-plus</v-icon></span
                      ><span>ADD More</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs12>
                <v-layout
                  wrap
                  justify-center
                  v-for="(person, index) in livestockanimal"
                  :key="index"
                >
                  <v-flex xs12 sm1 md1 lg1 class="text-center" pt-4>
                    <span>{{ index + 1 }}.</span>
                  </v-flex>
                  <v-flex xs12 sm5 md5 lg5>
                    <v-select
                      :items="depredationType"
                      label="Type Of Depredation"
                      outlined
                      v-model="person.injuryOrDeath"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-text-field
                      outlined
                      label="Type Of Animal"
                      placeholder="Enter Type Of Animal"
                      v-model="person.typeOfAnimal"
                      class="itemform"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex> -->
            </v-layout>
            <v-layout wrap justify-center pt-2>
              <v-flex xs12 align-self-center pb-1>
                <span class="SubitemHeading1">Land Details</span>
              </v-flex>
              <v-flex xs12>
                <v-select
                  :items="TypeofLand"
                  label="Land Type"
                  outlined
                  item-text="name"
                  item-value="_id"
                  v-model="landtype"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pb-2>
              <v-flex xs12 align-self-center>
                <span class="SubitemHeading1">Barrier Details</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 sm6 md6 lg6>
                <v-select
                  :items="barrierbreach"
                  label="Whether the conflict animal breached any barrier?"
                  outlined
                  v-model="breachbarrier"
                  class="itemform"
                  dense
                ></v-select>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <span v-if="breachbarrier === 'Yes'">
                  <v-select
                    :items="Typesofbarrier"
                    label="Barrier Type"
                    outlined
                    multiple
                    item-text="name"
                    item-value="_id"
                    v-model="barrierType"
                    class="itemform"
                    dense
                  ></v-select>
                </span>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <span v-if="breachbarrier === 'Yes'">
                  <v-select
                    :items="Typesofbreach"
                    label="Breach Type"
                    outlined
                    multiple
                    item-text="name"
                    item-value="_id"
                    v-model="breachType"
                    class="itemform"
                    dense
                  ></v-select>
                </span>
              </v-flex>
            </v-layout>

            <v-layout
              wrap
              justify-start
              v-if="editresolve && editresolve.isStepsForMitigation"
            >
              <v-flex xs12 align-self-center pb-2>
                <span class="SubitemHeading1">Steps Taken For Mitigation</span>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-center
              v-if="editresolve && editresolve.isStepsForMitigation"
            >
              <v-flex xs12>
                <v-layout
                  wrap
                  justify-center
                  v-for="(step, index) in mitigationSteps"
                  :key="index"
                >
                  <v-flex xs12 sm5 md6 lg6>
                    <v-select
                      :items="MitigationSteptypes"
                      label="Mitigation Steps"
                      outlined
                      v-model="step.name"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm4 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                    <v-menu
                      v-model="step.menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="step.mitigationStartDate"
                          label="Mitigation Start Date"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          class="itemform"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="step.mitigationStartDate"
                        @input="step.menu = false"
                        :min="reporteddate"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2 pt-1>
                    <span v-if="mitigationSteps.length > 0">
                      <v-btn
                        small
                        plain
                        @click="removeMitigationStep(index)"
                        color="error"
                        class="itemform"
                        dense
                      >
                        <span>Remove</span
                        ><span><v-icon small>mdi-delete</v-icon></span>
                      </v-btn>
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-end>
                  <v-flex xs12 sm3 md3 lg3>
                    <v-btn
                      small
                      plain
                      @click="addMitigationStep"
                      color="primary"
                    >
                      <span><v-icon small>mdi-plus</v-icon></span
                      ><span>ADD More</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- If there is steps for mitigation -->
            <v-layout
              wrap
              justify-start
              v-if="editresolve && editresolve.isStepsForMitigation === false"
            >
              <v-flex xs12>
                <v-layout wrap justify-start pb-2>
                  <v-flex xs12 align-self-center>
                    <span class="SubitemHeading1"
                      >Resolved & Released Details</span
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-select
                      :items="resolvedornotType"
                      label="Whether resolved or not?"
                      outlined
                      v-model="resolvedornot"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6
                    lg6
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                    v-if="resolvedornot === 'Yes'"
                  >
                    <span>
                      <v-select
                        :items="MitigationTypes"
                        label="Mitigation Types"
                        outlined
                        item-text="name"
                        item-value="_id"
                        v-model="mitigation"
                        class="itemform"
                        dense
                      ></v-select>
                    </span>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6
                    lg6
                    v-if="resolvedornot === 'No'"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>
                      <v-select
                        :items="IfnotResolvedTypes"
                        label="If Not Resolved?"
                        outlined
                        v-model="ifnotresolved"
                        class="itemform"
                        dense
                      ></v-select>
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center v-if="resolvedornot === 'Yes'">
                  <v-flex xs12>
                    <v-layout
                      wrap
                      justify-center
                      v-if="MitigationmethodTypes.length > 0"
                    >
                      <!-- {{editresolve.mitigationMethodId}}haal -->
                      <v-flex xs12 md6 sm6 lg6>
                        <v-select
                          :items="MitigationmethodTypes"
                          label="Mitigation Methods"
                          outlined
                          v-model="editresolve.mitigationMethodId"
                          item-text="name"
                          item-value="_id"
                          class="itemform"
                          dense
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 md6 sm6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                        <v-select
                          :items="ReleasedornotTypes"
                          label="Whether released or not?"
                          outlined
                          v-model="editreleasedornot"
                          class="itemform"
                          dense
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-start
                      v-if="editreleasedornot === 'No'"
                    >
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs12 sm4 md4 lg4>
                            <v-select
                              :items="whynotreleasedTypes"
                              label="Why not released?"
                              outlined
                              v-model="whynotreleased"
                              class="itemform"
                              dense
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-text-field
                              outlined
                              label="Location"
                              placeholder="Enter The Location"
                              v-model="locationnamenotresolved"
                              class="itemform"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm2 md2 lg2 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-menu
                              ref="menu5"
                              v-model="menu5"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="dateofcapture"
                                  label="Date"
                                  readonly
                                  v-bind="attrs"
                                  outlined
                                  v-on="on"
                                  class="itemform"
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dateofcapture"
                                no-title
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="menu5 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu5.save(dateofcapture)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs12 sm2 md2 lg2 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-menu
                              ref="menu"
                              v-model="menu4"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="time"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="timeofcapture"
                                  label="Time"
                                  outlined
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="itemform"
                                  dense
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="menu4"
                                v-model="timeofcapture"
                                full-width
                                @click:minute="$refs.menu.save(time)"
                              ></v-time-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <v-textarea
                              outlined
                              name="input-7-4"
                              label="Reason"
                              v-model="reasonofcapture"
                              class="itemform"
                            ></v-textarea>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-center
                      v-if="mitigation === '66a86f0c840cba1fbc0456ae'"
                    >
                      <v-flex xs12>
                        <span>
                          <v-text-field
                            outlined
                            label="How far into forest?(In Km)"
                            placeholder="Enter How far into forest?"
                            v-model="farintoforest"
                            type="number"
                            class="inputPrice itemform"
                            dense
                          ></v-text-field>
                        </span>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-center
                      v-if="
                        releasedornot === 'No' ||
                        mitigation === 'Provide Safe Passage' ||
                        resolvedornot === 'Yes'
                      "
                    >
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs12 sm4 md4 lg4>
                            <v-text-field
                              outlined
                              label="Resolve Location"
                              append-outer-icon="mdi-close"
                              append-icon="mdi-magnify"
                              placeholder="Enter Resolved Location"
                              v-model="resolvelocation"
                              type="text"
                              @click:append="resolveplotLocation"
                              @click:append-outer="resolveresetFields"
                              class="itemform"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-text-field
                              outlined
                              label="Latitude"
                              v-model="resolvelattitude"
                              type="number"
                              disabled
                              class="inputPrice itemform"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-text-field
                              outlined
                              label="Longitude"
                              v-model="resolvelongitude"
                              disabled
                              type="number"
                              class="inputPrice itemform"
                              dense
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs12 sm6 md6 lg6>
                            <v-select
                              :items="veterinarypresentornot"
                              label="Is veterinary present?"
                              outlined
                              v-model="veterinarypresent"
                              class="itemform"
                              dense
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                            <span v-if="veterinarypresent === 'Yes'">
                              <v-textarea
                                outlined
                                name="input-7-4"
                                label="Veterinary Recomendation"
                                v-model="veterinaryrecomendation"
                                class="itemform"
                                dense
                              ></v-textarea>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center v-if="resolvedornot === 'No'">
                  <v-flex xs12>
                    <v-layout
                      wrap
                      justify-center
                      v-if="ifnotresolved === 'Handing Over'"
                    >
                      <v-flex xs12 sm6 md6 lg6>
                        <span>
                          <v-select
                            :items="HandingOverTypes"
                            label="Handing Over To?"
                            outlined
                            v-model="handoverDetails.handoverplatform"
                            class="itemform"
                            dense
                          ></v-select>
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                        lg6
                        pl-lg-2
                        pl-md-2
                        pl-sm-2
                        v-if="handoverDetails.handoverplatform === 'Division'"
                      >
                        <span>
                          <v-autocomplete
                            outlined
                            label="Division Name"
                            :items="divisionList"
                            v-model="handoverDetails.divisionId"
                            item-text="divisionName"
                            item-value="_id"
                            class="itemform"
                            dense
                          >
                          </v-autocomplete>
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                        lg6
                        pl-lg-2
                        pl-md-2
                        pl-sm-2
                        v-if="handoverDetails.handoverplatform === 'Range'"
                      >
                        <span>
                          <v-autocomplete
                            outlined
                            label="Range Name"
                            :items="rangeList"
                            v-model="handoverDetails.rangeId"
                            item-text="rangeName"
                            item-value="_id"
                            class="itemform"
                            dense
                          >
                          </v-autocomplete>
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                        lg6
                        pl-lg-2
                        pl-md-2
                        pl-sm-2
                        v-if="handoverDetails.handoverplatform === 'RRT'"
                      >
                        <span>
                          <v-autocomplete
                            outlined
                            label="RRT Name"
                            :items="rrtList"
                            v-model="handoverDetails.RRT"
                            item-text="name"
                            item-value="_id"
                            class="itemform"
                            dense
                          >
                          </v-autocomplete>
                        </span>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-center
                      v-if="ifnotresolved === 'Steps taken for mitigation'"
                    >
                      <v-flex xs12>
                        <v-layout
                          wrap
                          justify-center
                          v-for="(step, index) in mitigationSteps"
                          :key="index"
                        >
                          <v-flex xs12 sm5 md6 lg6>
                            <v-select
                              :items="MitigationSteptypes"
                              label="Mitigation Steps"
                              outlined
                              v-model="step.name"
                              class="itemform"
                              dense
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm4 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-menu
                              v-model="step.menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="step.mitigationStartDate"
                                  label="Mitigation Start Date"
                                  readonly
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  class="itemform"
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="step.mitigationStartDate"
                                @input="step.menu = false"
                                :min="reporteddate"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2 pt-1>
                            <span v-if="mitigationSteps.length > 0">
                              <v-btn
                                small
                                plain
                                @click="removeMitigationStep(index)"
                                color="error"
                                class="itemform"
                                dense
                              >
                                <span>Remove</span
                                ><span><v-icon small>mdi-delete</v-icon></span>
                              </v-btn>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-end>
                          <v-flex xs12 sm3 md3 lg3>
                            <v-btn
                              small
                              plain
                              @click="addMitigationStep"
                              color="primary"
                            >
                              <span><v-icon small>mdi-plus</v-icon></span
                              ><span>ADD More</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- else-if -->
            <!-- 123 -->
            <v-layout
              wrap
              justify-start
              v-if="editresolve && editresolve.isStepsForMitigation === true"
            >
              <v-flex xs12>
                <v-layout wrap justify-start pb-2>
                  <v-flex xs12 align-self-center>
                    <span class="SubitemHeading1"
                      >Resolved & Released Details</span
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 sm6 md6 lg6>
                    <v-select
                      :items="resolvedornotType"
                      label="Whether resolved or not?"
                      outlined
                      v-model="editresolvedornot"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6
                    lg6
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                    v-if="editresolvedornot === 'Yes'"
                  >
                    <span>
                      <v-select
                        :items="MitigationTypes"
                        label="Mitigation Types"
                        outlined
                        item-text="name"
                        item-value="_id"
                        v-model="editmitigation"
                        class="itemform"
                        dense
                      ></v-select>
                    </span>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                    md6
                    lg6
                    v-if="editresolvedornot === 'No'"
                    pl-lg-2
                    pl-md-2
                    pl-sm-2
                  >
                    <span>
                      <v-select
                        :items="IfnotResolvedTypesavoidsteps"
                        label="If Not Resolved?"
                        outlined
                        v-model="editifnotresolved"
                        class="itemform"
                        dense
                      ></v-select>
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout
                  wrap
                  justify-center
                  v-if="editresolvedornot === 'Yes'"
                >
                  <v-flex xs12>
                    <v-layout
                      wrap
                      justify-center
                      v-if="MitigationmethodTypes.length > 0"
                    >
                      <v-flex xs12 md6 sm6 lg6>
                        <v-select
                          :items="MitigationmethodTypes"
                          label="Mitigation Methods"
                          outlined
                          v-model="editmitigationmethods"
                          item-text="name"
                          item-value="_id"
                          class="itemform"
                          dense
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 md6 sm6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                        <v-select
                          :items="ReleasedornotTypes"
                          label="Whether released or not?"
                          outlined
                          v-model="editreleasedornot"
                          class="itemform"
                          dense
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-start
                      v-if="editreleasedornot === 'No'"
                    >
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs12 sm4 md4 lg4>
                            <v-select
                              :items="whynotreleasedTypes"
                              label="Why not released?"
                              outlined
                              v-model="editwhynotreleased"
                              class="itemform"
                              dense
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-text-field
                              outlined
                              label="Location"
                              placeholder="Enter The Location"
                              v-model="editlocationnamenotresolved"
                              class="itemform"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm2 md2 lg2 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-menu
                              ref="menu5"
                              v-model="menu5"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editdateofcapture"
                                  label="Date"
                                  readonly
                                  v-bind="attrs"
                                  outlined
                                  v-on="on"
                                  class="itemform"
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editdateofcapture"
                                no-title
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="menu5 = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs12 sm2 md2 lg2 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-menu
                              ref="menu"
                              v-model="menu4"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="time"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="edittimeofcapture"
                                  label="Time"
                                  outlined
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="itemform"
                                  dense
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="menu4"
                                v-model="edittimeofcapture"
                                full-width
                                @click:minute="$refs.menu.save(time)"
                              ></v-time-picker>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <v-textarea
                              outlined
                              name="input-7-4"
                              label="Reason"
                              v-model="editreasonofcapture"
                              class="itemform"
                            ></v-textarea>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-center
                      v-if="editmitigation === '66a86f0c840cba1fbc0456ae'"
                    >
                      <v-flex xs12>
                        <span>
                          <v-text-field
                            outlined
                            label="How far into forest?(In Km)"
                            placeholder="Enter How far into forest?"
                            v-model="editfarintoforest"
                            type="number"
                            class="inputPrice itemform"
                            dense
                          ></v-text-field>
                        </span>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-center
                      v-if="
                        editresolvedornot === 'No' ||
                        editmitigation === 'Provide Safe Passage' ||
                        editresolvedornot === 'Yes'
                      "
                    >
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs12 sm4 md4 lg4>
                            <v-text-field
                              outlined
                              label="Resolve Location"
                              append-outer-icon="mdi-close"
                              append-icon="mdi-magnify"
                              placeholder="Enter Resolved Location"
                              v-model="editresolvelocation"
                              type="text"
                              @click:append="editresolveplotLocation"
                              @click:append-outer="editresolveresetFields"
                              class="itemform"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-text-field
                              outlined
                              label="Latitude"
                              v-model="editresolvelattitude"
                              type="number"
                              disabled
                              class="inputPrice itemform"
                              dense
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-text-field
                              outlined
                              label="Longitude"
                              v-model="editresolvelongitude"
                              disabled
                              type="number"
                              class="inputPrice itemform"
                              dense
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs12 sm6 md6 lg6>
                            <v-select
                              :items="veterinarypresentornot"
                              label="Is veterinary present?"
                              outlined
                              v-model="editveterinarypresent"
                              class="itemform"
                              dense
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                            <span v-if="editveterinarypresent === 'Yes'">
                              <v-textarea
                                outlined
                                name="input-7-4"
                                label="Veterinary Recomendation"
                                v-model="editveterinaryrecomendation"
                                class="itemform"
                                dense
                              ></v-textarea>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- <v-flex xs12 sm4 md4 lg4>
                        <v-text-field
                          outlined
                          label="Resolve Location"
                          placeholder="Enter Resolved Location"
                          v-model="editresolvelocation"
                          type="text"
                          class="itemform"
                          dense
                        ></v-text-field>
                      </v-flex> -->
                      <!-- <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                        <v-select
                          :items="veterinarypresentornot"
                          label="Is veterinary present?"
                          outlined
                          v-model="editveterinarypresent"
                          class="itemform"
                          dense
                        ></v-select>
                      </v-flex> -->
                      <!-- <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                        <span v-if="editveterinarypresent === 'Yes'">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Veterinary Recomendation"
                            v-model="editveterinaryrecomendation"
                            class="itemform"
                            dense
                          ></v-textarea>
                        </span>
                      </v-flex> -->
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center v-if="editresolvedornot === 'No'">
                  <v-flex xs12>
                    <v-layout
                      wrap
                      justify-center
                      v-if="editifnotresolved === 'Handing Over'"
                    >
                      <v-flex xs12 sm6 md6 lg6>
                        <span>
                          <v-select
                            :items="HandingOverTypes"
                            label="Handing Over To?"
                            outlined
                            v-model="edithandoverDetails.handoverplatform"
                            class="itemform"
                            dense
                          ></v-select>
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                        lg6
                        pl-lg-2
                        pl-md-2
                        pl-sm-2
                        v-if="
                          edithandoverDetails.handoverplatform === 'Division'
                        "
                      >
                        <span>
                          <v-autocomplete
                            outlined
                            label="Division Name"
                            :items="divisionList"
                            v-model="edithandoverDetails.divisionId"
                            item-text="divisionName"
                            item-value="_id"
                            class="itemform"
                            dense
                          >
                          </v-autocomplete>
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                        lg6
                        pl-lg-2
                        pl-md-2
                        pl-sm-2
                        v-if="edithandoverDetails.handoverplatform === 'Range'"
                      >
                        <span>
                          <v-autocomplete
                            outlined
                            label="Range Name"
                            :items="rangeList"
                            v-model="edithandoverDetails.rangeId"
                            item-text="rangeName"
                            item-value="_id"
                            class="itemform"
                            dense
                          >
                          </v-autocomplete>
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                        lg6
                        pl-lg-2
                        pl-md-2
                        pl-sm-2
                        v-if="edithandoverDetails.handoverplatform === 'RRT'"
                      >
                        <span>
                          <v-autocomplete
                            outlined
                            label="RRT Name"
                            :items="rrtList"
                            v-model="edithandoverDetails.RRT"
                            item-text="name"
                            item-value="_id"
                            class="itemform"
                            dense
                          >
                          </v-autocomplete>
                        </span>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      wrap
                      justify-center
                      v-if="editifnotresolved === 'Steps taken for mitigation'"
                    >
                      <v-flex xs12>
                        <v-layout
                          wrap
                          justify-center
                          v-for="(step, index) in editmitigationSteps"
                          :key="index"
                        >
                          <v-flex xs12 sm5 md6 lg6>
                            <v-select
                              :items="MitigationSteptypes"
                              label="Mitigation Steps"
                              outlined
                              v-model="step.name"
                              class="itemform"
                              dense
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm4 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                            <v-menu
                              v-model="step.menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="step.mitigationStartDate"
                                  label="Mitigation Start Date"
                                  readonly
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  class="itemform"
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="step.mitigationStartDate"
                                @input="step.menu = false"
                                :min="reporteddate"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2 pt-1>
                            <span v-if="mitigationSteps.length > 0">
                              <v-btn
                                small
                                plain
                                @click="removeeditMitigationStep(index)"
                                color="error"
                                class="itemform"
                                dense
                              >
                                <span>Remove</span
                                ><span><v-icon small>mdi-delete</v-icon></span>
                              </v-btn>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-end>
                          <v-flex xs12 sm3 md3 lg3>
                            <v-btn
                              small
                              plain
                              @click="addeditMitigationStep"
                              color="primary"
                            >
                              <span><v-icon small>mdi-plus</v-icon></span
                              ><span>ADD More</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout
              wrap
              justify-start
              pb-2
              v-if="
                (editresolve && editresolve.isResolved) ||
                editresolvedornot === 'Yes'
              "
            >
              <v-flex xs12 align-self-center>
                <span class="SubitemHeading1">Resolve Images</span>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-start
              v-if="editresolvedornot === 'Yes' || editresolve.isResolved"
            >
              <v-flex xs12>
                <v-layout wrap justify-center pa-5 style="border: solid 1px">
                  <v-flex xs12 text-left>
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        v-if="editdisplayImage && editdisplayImage.length > 0"
                      >
                        <v-layout wrap justify-start>
                          <v-flex
                            xs12
                            sm3
                            md3
                            lg3
                            pa-5
                            v-for="(items, index) in editdisplayImage"
                            :key="index"
                          >
                            <v-img
                              :src="mediaURL + items"
                              fill-height
                              height="100%"
                              width="100%"
                            >
                              <v-layout wrap>
                                <v-flex text-right pa-0>
                                  <v-avatar color="#FF3434" size="15">
                                    <v-icon
                                      color="#FFF"
                                      small
                                      @click="removeeditImage(index)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-avatar>
                                </v-flex>
                              </v-layout>
                            </v-img>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex
                        xs12
                        v-if="displayImage && displayImage.length > 0"
                      >
                        <v-layout wrap justify-start>
                          <v-flex
                            xs12
                            sm3
                            md3
                            lg3
                            pa-5
                            v-for="(items, index) in displayImage"
                            :key="index"
                          >
                            <v-img
                              :src="items"
                              fill-height
                              height="100%"
                              width="100%"
                            >
                              <v-layout wrap>
                                <v-flex text-right pa-0>
                                  <v-avatar color="#FF3434" size="15">
                                    <v-icon
                                      color="#FFF"
                                      small
                                      @click="removeImage(index)"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </v-avatar>
                                </v-flex>
                              </v-layout>
                            </v-img>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-end>
                      <v-flex xs12 text-right>
                        <v-btn
                          color="blue-grey"
                          dark
                          small
                          @click="$refs.uploadDoc.click()"
                          class="itemform"
                          ><span> UPLOAD IMAGES </span>
                          <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                        >
                      </v-flex>
                      <input
                        v-show="false"
                        id="file1"
                        ref="uploadDoc"
                        multiple
                        type="file"
                        accept=".svg, .png, .jpg, .jpeg"
                        @change="uploadDoc"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-2>
              <v-flex xs12>
                <v-btn
                  block
                  tile
                  :color="appColor"
                  light
                  :ripple="false"
                  depressed
                  @click="handleSubmit"
                  class="itemValue"
                >
                  <span style="color: #fff">SUBMIT</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDPNXCjI6IJbDqwJcJwGuJUfNWxfMLSe-U&libraries=visualization,drawing"></script>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      time: null,
      timeofcapture: null,
      menu2: false,
      menu4: false,
      geocoder: new google.maps.Geocoder(),
      menu3: false,
      menu5: false,
      modal2: false,
      lattitude: "",
      longitude: "",
      locationname: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mitigationstartdate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      dateofcapture: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menu: false,
      animalTypes: [],
      // animaltype: this.$route.query.animalid,
      animaltype: "",
      sightingsorconflicts: [],
      sightingorconflict: ["Mere Sighting", "Involved in Conflict"],
      sightingorconflicttype: "",
      insideforesttypes: ["Yes", "No"],
      insideforest: "",
      reservedorprotectedname: "",
      animalpresenttype: ["Yes", "No"],
      animalpresent: "",
      numofanimalslist: ["Single", "Group"],
      noofanimals: "",
      noofanimalslocal: "",
      singleDetails: {
        animalAge: "",
        animalStatusId: "",
        genderId: "",
      },
      groupDetails: {
        adultCount: "",
        subAdultCount: "",
        juvenileCount: "",
        calfCount: "",
        maleCount: "",
        femaleCount: "",
        cannotDetermineCount: "",
        animalStatus: "",
      },
      resolvelongitude: "",
      resolvelattitude: "",
      ageofanimal: [],
      animalage: "",
      statusofanimal: [],
      animalstatus: "",
      genderofanimal: [],
      animalgender: "",
      noofadultanimals: "",
      noofsubadultanimals: "",
      noofJuvenileanimals: "",
      noofCalfanimals: "",
      noofhealthyanimals: "",
      noofUnhealthyanimals: "",
      noofInjuredanimals: "",
      noofWeakanimals: "",
      noofInfectedanimals: "",
      visiblesigntypes: ["Yes", "No"],
      visiblesign: "",
      noofMaleanimals: "",
      noofFemaleanimals: "",
      noofCannotdeterminedanimals: "",
      statusofanimalgrouptypes: [
        "No visible sign",
        "Atleast one animal unhealthy",
        "Atleast one animal injured",
        "Atleast one animal weak",
        "Atleast one animal found to be infected",
      ],
      statusofanimalgroup: "",
      ActivityofAnimals: [],
      animalactivity: "",
      ConflictTypes: [],
      ConflictType: [],
      Unitsofcrops: [
        "Acre",
        "Hectare",
        "Square Meter",
        "Square Kilometer",
        "Nos",
      ],
      cropDamageDetails: {
        cropId: "",
        count: "",
      },
      Typeofcrops: [],
      croptype: "",
      propertyDamageDetails: {
        propertyType: "",
        damageDetail: "",
      },
      Typeofproperty: ["Building", "Road"],
      propertytype: "",
      damagedetails: "",
      // nopeopleinjured:"",
      nopeopleinjured: 0,
      injuredPeople: [],
      // Typeofinjury: ["Broken Arm", "Broken Leg"],
      injurytype: "",
      nameofpersoninjured: "",
      ageofpersoninjured: "",
      genderofpersoninjured: "",
      persongender: ["Male", "Female", "Other"],
      WhetherHospitalized: ["Yes", "No"],
      hospitalizedinjured: "",
      // nopeopledied:"",
      nopeopledied: 0,

      namepersondied: "",
      agepersondied: "",
      genderofpersondied: "",
      peopleDead: [],
      animalInjuryDetails: {
        injuredNumber: "",
        typeOfInjury: "",
      },
      animalDeathDetails: {
        numberOfDied: "",
      },
      numberofanimalinjured: "",
      numberofanimaldied: "",
      depredationType: ["Injury", "Death"],
      livestockdepredationtype: "",
      livestockdepredationanimal: "",
      numberoflivestockdepredationanimal: 0,
      livestockanimal: [
        { injuryOrDeath: "", typeOfAnimal: "", numberOfAnimals: "" },
      ],
      animalinjurytype: "",
      barrierbreach: ["Yes", "No"],
      breachbarrier: "",
      Typesofbarrier: [],
      barrierType: [],
      TypeofLand: [],
      landtype: "",
      Typesofbreach: [],
      breachType: [],
      resolvedornot: "",
      resolvedornotType: ["Yes", "No"],
      mitigation: "",
      MitigationTypes: [],
      MitigationmethodTypes: [],
      mitigationmethods: "",
      farintoforest: "",
      treatmentDetails: {
        locationnamenotresolved: "",
        dateofcapture: "",
        timeofcapture: "",
      },
      resolvelocation: "",
      veterinarypresentornot: ["Yes", "No"],
      veterinarypresent: "",
      veterinaryrecomendation: "",
      IfnotResolvedTypes: ["Handing Over", "Steps taken for mitigation"],
      IfnotResolvedTypesavoidsteps: ["Handing Over"],
      ifnotresolved: "",
      HandingOverTypes: ["Division", "Range", "RRT"],
      handingover: "",
      divisionname: "",
      divisionList: [],
      rangename: "",
      rangeList: [],
      rrtname: "",
      editresolvelattitude: "",
      editresolvelongitude: "",
      rrtList: [],
      MitigationSteptypes: [
        "Deploy Camera Trap",
        "Deploy Trap Cage",
        "Initiated Intensified Patroling",
        "Started chasing the animal",
      ],
      mitigationstep: "",
      mitigationSteps: [
        {
          name: "",
          mitigationStartDate: "",
          menu: false,
        },
      ],
      editmitigationSteps: [
        {
          name: "",
          mitigationStartDate: "",
          menu: false,
        },
      ],
      cropTypedetails: [{ cropId: "", count: "", unit: "" }],
      propertyTypedetails: [{ propertyType: "", damageDetail: "" }],
      locationnamenotresolved: "",
      ReleasedornotTypes: ["Yes", "No"],
      releasedornot: "",
      whynotreleasedTypes: ["Treatment", "Permanent"],
      whynotreleased: "",
      reasonofcapture: "",
      caseDetails: [],
      id: this.$route.query.id,
      animalname: this.$route.query.name,
      locationnameofincident: this.$route.query.location,
      reporteddate: this.$route.query.date,
      handoverDetails: {
        handoverplatform: "",
        divisionId: "",
        rangeId: "",
        RRT: "",
      },
      docs1: [],
      docs1Public: [],
      docsPublic: [],
      docs: [],
      displayImage: [],
      editdisplayImage: [],
      userList: [],
      userArray: [],
      newArray: [],
      doc: null,
      file: null,
      keyword: "",
      registerAssets: {},
      assetId: "",
      search: "",
      Rules: [(value) => !!value || "Required."],
      fileArray: [],
      byte: "",
      kb: "",
      mb: "",
      gb: "",

      // EDIT
      editresolve: {},
      editresolvedornot: "",
      editresolvedornotType: ["Yes", "No"],
      editmitigation: "",
      editifnotresolved: "",
      editmitigationmethods: "",
      editreleasedornot: "",
      editwhynotreleased: "",
      editlocationnamenotresolved: "",
      editdateofcapture: "",
      edittimeofcapture: "",
      editreasonofcapture: "",
      editfarintoforest: "",
      editresolvelocation: "",
      editveterinarypresent: "",
      editveterinaryrecomendation: "",
      edithandoverDetails: {
        handoverplatform: "",
        divisionId: "",
        rangeId: "",
        RRT: "",
      },
      removedImages: [],
    };
  },
  created() {
    this.setCurrentTime();
  },

  watch: {
    numberoflivestockdepredationanimal(newCount) {
      const count = Number(newCount);
      this.livestockanimal = Array.from({ length: count }, (_, index) => ({
        injuryOrDeath: this.livestockanimal[index]?.injuryOrDeath || "",
        typeOfAnimal: this.livestockanimal[index]?.typeOfAnimal || "",
      }));
    },
    nopeopleinjured(newCount) {
      const count = Number(newCount);
      this.injuredPeople = Array.from({ length: count }, (_, index) => {
        // If already exists, return the existing person, else create new
        return (
          this.injuredPeople[index] || {
            injurytype: "",
            nameofpersoninjured: "",
            ageofpersoninjured: "",
            genderofpersoninjured: "",
            hospitalizedinjured: "",
          }
        );
      });
    },
    nopeopledied(newCount) {
      const count = Number(newCount);
      this.peopleDead = Array.from({ length: count }, (_, index) => {
        // If already exists, return the existing person, else create new
        return (
          this.peopleDead[index] || {
            namepersondied: "",
            agepersondied: "",
            genderofpersondied: "",
          }
        );
      });
    },
    animalpresent() {
      this.noofanimals = "";
      this.noofanimalslocal = "";
    },
    mitigation() {
      this.mitigationmethods = "";
      this.releasedornot = "";
      this.getmitigationmethodtypeData();
    },
    editmitigation() {
      this.editmitigationmethods = "";
      this.editreleasedornot = "";
      this.geteditmitigationmethodtypeData();
    },
    animaltype() {
      this.getanimalageData();
    },
  },
  computed: {
    formattedReportedDate() {
      if (!this.reporteddate) return "";
      const date = new Date(this.reporteddate);
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    },
    formattedAgeOfAnimal() {
      return this.ageofanimal.map((item) => {
        return {
          _id: item._id,
          category: `${item.category} (${item.min_age}-${item.max_age})`,
        };
      });
    },
    isCropDamageSelected() {
      return this.ConflictType.includes("66a0a27355c32d4f84bae66c");
    },
    isPropertyDamageSelected() {
      return this.ConflictType.includes("66a0a2eb55c32d4f84bae66f");
    },
    isHumanInjurySelected() {
      return this.ConflictType.includes("66a89372178e6931b4c1753f");
    },
    isHumanDeathSelected() {
      return this.ConflictType.includes("66a89379178e6931b4c17543");
    },
    isAnimalInjurySelected() {
      return this.ConflictType.includes("66a89360178e6931b4c17537");
    },
    isAnimalDeathSelected() {
      return this.ConflictType.includes("66a89368178e6931b4c1753b");
    },
    isLivestockDepredationSelected() {
      return this.ConflictType.includes("66a89381178e6931b4c17547");
    },
  },
  beforeMount() {
    this.getanimalData();
    this.getscenarioData();
    this.getanimalactivityData();
    this.getconflicttypeData();
    this.getlandtypeData();
    this.getbarriertypeData();
    this.getbreachtypeData();
    this.getmitigationtypeData();
    this.getanimalstatusData();
    this.getanimalgenderData();
    this.getcroptypeData();
    this.getDivisionData();
    this.getRangeData();
    this.getRRTData();
    this.getData();
    if (this.animaltype) {
      this.getanimalageData();
    }
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/resolve/view",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.editresolve = response.data.data;
              this.lattitude = this.editresolve.location[0];
              this.longitude = this.editresolve.location[1];
              this.resolvelattitude = this.editresolve.resolvedLocation[0];
              this.resolvelongitude = this.editresolve.resolvedLocation[1];
              this.locationname = this.editresolve.actualIncidentLocation;
              this.time = this.editresolve.actualIncidentTime;
              this.date = new Date(this.editresolve.actualIncidentDate)
                .toISOString()
                .split("T")[0];
              this.sightingorconflicttype = this.editresolve.sightingOrConflict;
              if (this.editresolve.isInsideForest) {
                this.insideforest = "Yes";
              } else {
                this.insideforest = "No";
              }
              this.reservedorprotectedname = this.editresolve.RForPAName;
              if (this.editresolve.animalPresentOrNot) {
                this.animalpresent = "Yes";
              } else {
                this.animalpresent = "No";
              }

              this.singleDetails = this.editresolve.singleDetails;
              this.groupDetails = this.editresolve.groupDetails;
              this.animalactivity = this.editresolve.animalActivityId;
              this.animaltype = this.editresolve.animalId._id;
              this.ConflictType = this.editresolve.conflictTypeId.map(
                (item) => item._id
              );
              this.landtype = this.editresolve.landTypeId;
              if (this.editresolve.isBreachedBarrier) {
                this.breachbarrier = "Yes";
              } else {
                this.breachbarrier = "No";
              }
              this.barrierType = this.editresolve.barrierTypeId.map(
                (item) => item._id
              );
              this.breachType = this.editresolve.breachTypeId.map(
                (item) => item._id
              );
              if (this.editresolve.isResolved) {
                this.resolvedornot = "Yes";
              } else {
                this.resolvedornot = "No";
              }
              // this.mitigation = this.editresolve.mitigationTypeId._id;
              if (
                this.editresolve.mitigationTypeId &&
                this.editresolve.mitigationTypeId._id
              ) {
                this.mitigation = this.editresolve.mitigationTypeId._id;
              }
              this.farintoforest = this.editresolve.howFarintoForest;
              if (this.editresolve.isReleased) {
                this.editreleasedornot = "Yes";
              } else {
                this.editreleasedornot = "No";
              }
              console.log("isReleased value:", this.editresolve.isReleased);
              this.resolvelocation = this.editresolve.resolvedLocationName;
              if (this.editresolve.isVeterinaryPresent) {
                this.veterinarypresent = "Yes";
              } else {
                this.veterinarypresent = "No";
              }
              this.veterinaryrecomendation =
                this.editresolve.veterinaryRecommendations;
              if (this.editresolve.isTreatment) {
                this.whynotreleased = "Treatment";
              } else {
                this.whynotreleased = "Permanent";
              }
              if (this.editresolve.isTreatment) {
                this.locationnamenotresolved =
                  this.editresolve.treatmentDetails.treatmentPlace;
                this.dateofcapture = new Date(
                  this.editresolve.treatmentDetails.treatmentDate
                )
                  .toISOString()
                  .split("T")[0];

                this.timeofcapture =
                  this.editresolve.treatmentDetails.treatmentTime;
                this.reasonofcapture = this.editresolve.treatmentDetails.reason;
              }
              if (this.editresolve.isPermanent) {
                this.locationnamenotresolved =
                  this.editresolve.permanentDetails.permanentPlace;
                this.dateofcapture = new Date(
                  this.editresolve.permanentDetails.permanentDate
                )
                  .toISOString()
                  .split("T")[0];
                this.timeofcapture =
                  this.editresolve.permanentDetails.permanentTime;
                this.reasonofcapture = this.editresolve.permanentDetails.reason;
              }
              this.cropTypedetails = this.editresolve.cropDamageDetails.map(
                (item) => ({
                  cropId: item.cropId._id,
                  count: item.count,
                  unit: item.unit,
                })
              );
              this.propertyTypedetails =
                this.editresolve.propertyDamageDetails.map((item) => ({
                  propertyType: item.propertyType,
                  damageDetail: item.damageDetail,
                }));
              // this.displayImage = this.editresolve.resolveImages;
              this.editdisplayImage = this.editresolve.resolveImages;
              this.nopeopleinjured =
                this.editresolve.humanInjuryDetails.injuredNumber;
              this.injuredPeople =
                this.editresolve.humanInjuryDetails.personDetails.map(
                  (person) => ({
                    injurytype: person.typeOfInjury,
                    nameofpersoninjured: person.name,
                    ageofpersoninjured: person.age,
                    genderofpersoninjured: person.gender,
                    hospitalizedinjured: person.isHospitalized ? "Yes" : "No",
                  })
                );
              this.nopeopledied =
                this.editresolve.humanDeathDetails.numberOfDied;
              this.peopleDead =
                this.editresolve.humanDeathDetails.personDetails.map(
                  (person) => ({
                    namepersondied: person.name,
                    agepersondied: person.age,
                    genderofpersondied: person.gender,
                  })
                );
              this.animalInjuryDetails.injuredNumber =
                this.editresolve.animalInjuryDetails.injuredNumber;
              this.animalInjuryDetails.typeOfInjury =
                this.editresolve.animalInjuryDetails.typeOfInjury;
              this.animalDeathDetails.numberOfDied =
                this.editresolve.animalDeathDetails.numberOfDied;
              this.numberoflivestockdepredationanimal =
                this.editresolve.livestockAnimalCount;
              this.livestockanimal =
                this.editresolve.livestockDepredationDetails.map((item) => ({
                  injuryOrDeath: item.injuryOrDeath,
                  typeOfAnimal: item.typeOfAnimal,
                  numberOfAnimals: item.numberOfAnimals,
                }));
              if (this.editresolve.isStepsForMitigation) {
                this.ifnotresolved = "Steps taken for mitigation";
              } else {
                this.ifnotresolved = "Handing Over";
              }
              this.mitigationSteps = this.editresolve.stepsForMitigation.map(
                (step) => ({
                  name: step.name,
                  mitigationStartDate: new Date(step.mitigationStartDate)
                    .toISOString()
                    .split("T")[0],
                  menu: false,
                })
              );
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeImage(index) {
      this.displayImage.splice(index, 1);
      this.docs.splice(index, 1);
      this.fileArray.splice(index, 1);
    },
    removeeditImage(index) {
      const removedImageName = this.editdisplayImage[index];
      this.removedImages.push(removedImageName);

      // Remove the image from the existing arrays
      this.editdisplayImage.splice(index, 1);
      this.docs.splice(index, 1);
      this.fileArray.splice(index, 1);
    },
    plotLocation() {
      if (this.locationname) {
        this.geocoder.geocode(
          { address: this.locationname },
          (results, status) => {
            if (status === "OK") {
              const location = results[0].geometry.location;
              const lat = location.lat();
              const lng = location.lng();

              // Kerala latitude and longitude boundaries
              const isInKerala =
                lat >= 8.17 && lat <= 12.93 && lng >= 74.51 && lng <= 77.6;

              if (isInKerala) {
                this.lattitude = lat;
                this.longitude = lng;
              } else {
                // Log the fetched location for debugging
                // console.log(`Latitude: ${lat}, Longitude: ${lng}`);
                // console.error("Location is outside Kerala.");
                this.msg = "Location must be within Kerala.";
                this.showSnackBar = true;
                this.lattitude = ""; // Reset latitude
                this.longitude = ""; // Reset longitude
              }
            } else {
              console.error(
                "Geocode was not successful for the following reason: " + status
              );
              this.msg = "Unable to retrieve location. Please try again.";
              this.showSnackBar = true;
            }
          }
        );
      } else {
        this.msg = "Please enter a location name.";
        this.showSnackBar = true;
      }
    },
    resetFields() {
      // Clear the fields
      this.lattitude = "";
      this.longitude = "";
      this.locationname = "";
    },
    uploadDoc(event) {
      this.docs1 = event.target.files || event.dataTransfer.files;

      if (this.docs.length < 5) {
        for (let i = 0; i < this.docs1.length; i++) {
          if (this.fileArray.includes(this.docs1[i].name)) {
            this.msg = "Duplicate File";
            this.showSnackBar = true;
          } else {
            const urls = URL.createObjectURL(this.docs1[i]);
            this.displayImage.push(urls);
            this.docs.push(this.docs1[i]);
            console.log("file", this.docs1[i]);
            console.log("haii img", this.docs);
          }
          this.fileArray.push(this.docs1[i].name);
          this.checkbox = false;
          // this.checkbox1 = false;
          this.checkbox3 = false;
          // this.checkbox2 = false;
          this.docs1[i].sizeCopy = this.docs1[i].size + "Bytes";
          if (this.docs1[i].size >= 1024) {
            this.byte = this.docs1[i].size;
            this.kb = this.byte / 1024;
            if (this.kb >= 1024) {
              this.mb = (this.byte / 1048576).toFixed(2);
              if (this.mb >= 1024) {
                this.gb = (this.byte / 1073741824).toFixed(2);
                this.docs1[i].sizeCopy = this.gb + "GB";
              } else {
                this.docs1[i].sizeCopy = this.mb + "MB";
              }
            } else {
              this.docs1[i].sizeCopy = this.kb.toFixed(2) + "KB";
            }
          }
        }
        // this.addToArray();
      } else {
        this.msg = "Maximum 5 images can be uploaded";
        this.showSnackBar = true;
      }
    },
    formatHumanInjuryDetails() {
      return {
        injuredNumber: this.nopeopleinjured,
        personDetails: this.injuredPeople.map((person) => ({
          name: person.nameofpersoninjured,
          age: person.ageofpersoninjured,
          gender: person.genderofpersoninjured,
          typeOfInjury: person.injurytype,
          isHospitalized: person.hospitalizedinjured === "Yes",
        })),
      };
    },
    formathumanDeathDetails() {
      return {
        numberOfDied: this.nopeopledied,
        personDetails: this.peopleDead.map((person) => ({
          name: person.namepersondied,
          age: person.agepersondied,
          gender: person.genderofpersondied,
        })),
      };
    },
    formattreatmentDetails() {
      return {
        treatmentPlace: this.locationnamenotresolved,
        treatmentDate: this.dateofcapture,
        treatmentTime: this.timeofcapture,
        reason: this.reasonofcapture,
      };
    },
    formatedittreatmentDetails() {
      return {
        treatmentPlace: this.editlocationnamenotresolved,
        treatmentDate: this.editdateofcapture,
        treatmentTime: this.edittimeofcapture,
        reason: this.editreasonofcapture,
      };
    },
    formatpermenantDetails() {
      return {
        permanentPlace: this.locationnamenotresolved,
        permanentDate: this.dateofcapture,
        permanentTime: this.timeofcapture,
        reason: this.reasonofcapture,
      };
    },
    formateditpermenantDetails() {
      return {
        permanentPlace: this.editlocationnamenotresolved,
        permanentDate: this.editdateofcapture,
        permanentTime: this.edittimeofcapture,
        reason: this.editreasonofcapture,
      };
    },
    handleSubmit() {
      if (!this.lattitude) {
        this.msg = "Please Provide The Lattitude.";
        this.showSnackBar = true;
        return;
      }
      if (!this.longitude) {
        this.msg = "Please Provide The longitude.";
        this.showSnackBar = true;
        return;
      }
      if (!this.locationname) {
        this.msg = "Please Provide Actual Incident Location.";
        this.showSnackBar = true;
        return;
      }
      if (!this.time) {
        this.msg = "Please Provide Time Of Incident.";
        this.showSnackBar = true;
        return;
      }
      if (!this.date) {
        this.msg = "Please Provide Date Of Incident.";
        this.showSnackBar = true;
        return;
      }
      if (!this.animaltype) {
        this.msg = "Please Provide Animal Type.";
        this.showSnackBar = true;
        return;
      }
      if (!this.sightingorconflicttype) {
        this.msg = "Please Provide Sighting Type.";
        this.showSnackBar = true;
        return;
      }
      if (!this.insideforest) {
        this.msg =
          "Please Provide Whether The Animal Is Inside The Forest Or Not.";
        this.showSnackBar = true;
        return;
      }
      if (!this.reservedorprotectedname) {
        this.msg = "Please Provide RF/PA Name.";
        this.showSnackBar = true;
        return;
      }
      if (!this.animalpresent) {
        this.msg =
          "Please Provide Whether The Animal Was Present When The Rescue Team Reached.";
        this.showSnackBar = true;
        return;
      }
      if (this.animalpresent === "Yes" && !this.editresolve.singleOrGroup) {
        this.msg = "Please Provide Number Of Animals.";
        this.showSnackBar = true;
        return;
      }
      if (this.noofanimals === "Single" && !this.singleDetails.animalAge) {
        this.msg = "Please Provide Age Of The Animal.";
        this.showSnackBar = true;
        return;
      }
      if (this.noofanimals === "Single" && !this.singleDetails.animalStatusId) {
        this.msg = "Please Provide Status Of The Animal.";
        this.showSnackBar = true;
        return;
      }
      if (this.noofanimals === "Single" && !this.singleDetails.genderId) {
        this.msg = "Please Provide Gender Of The Animal.";
        this.showSnackBar = true;
        return;
      }
      if (!this.animalactivity) {
        this.msg = "Please Provide Activity Of Animal.";
        this.showSnackBar = true;
        return;
      }
      // if (!this.ConflictType.length > 0) {
      //   this.msg = "Please Provide Type Of Conflict.";
      //   this.showSnackBar = true;
      //   return;
      // }
      if (!this.landtype) {
        this.msg = "Please Provide Type Of Land.";
        this.showSnackBar = true;
        return;
      }
      if (this.breachbarrier === "Yes" && !this.barrierType.length > 0) {
        this.msg = "Please Provide Barrier Type.";
        this.showSnackBar = true;
        return;
      }
      if (this.breachbarrier === "Yes" && !this.breachType.length > 0) {
        this.msg = "Please Provide Breach Type.";
        this.showSnackBar = true;
        return;
      }
      if (this.breachbarrier === "Yes" && !this.breachType.length > 0) {
        this.msg = "Please Provide Breach Type.";
        this.showSnackBar = true;
        return;
      }
      if (this.veterinarypresent === "Yes" && !this.veterinaryrecomendation) {
        this.msg = "Please Provide Veterinary Recommendation.";
        this.showSnackBar = true;
        return;
      }
      if (this.resolvedornot === "Yes" && !this.mitigation) {
        this.msg = "Please Provide Mitigation Type.";
        this.showSnackBar = true;
        return;
      }
      if (this.handingover === "Division" && !this.divisionname) {
        this.msg = "Please Provide Division Name.";
        this.showSnackBar = true;
        return;
      }
      if (this.handingover === "Range" && !this.rangename) {
        this.msg = "Please Provide Range Name.";
        this.showSnackBar = true;
        return;
      }
      if (this.handingover === "RRT" && !this.rrtname) {
        this.msg = "Please Provide RRT Name.";
        this.showSnackBar = true;
        return;
      }
      if (this.resolvedornot === "Yes" && !this.resolvelocation) {
        this.msg = "Please Provide Resolve Location.";
        this.showSnackBar = true;
        return;
      }
      if (
        this.resolvelocation &&
        (!this.resolvelattitude || !this.resolvelongitude)
      ) {
        this.msg =
          "Please Provide Lattitude And Longitude Of Resolve Locatiion.";
        this.showSnackBar = true;
        return;
      }
      const formData = new FormData();
      var animalactivityidd = null;
      if (typeof this.animalactivity === "object") {
        animalactivityidd = this.animalactivity._id;
      } else {
        animalactivityidd = this.animalactivity;
      }
      var landtypeidd = null;
      if (typeof this.landtype === "object") {
        landtypeidd = this.landtype._id;
      } else {
        landtypeidd = this.landtype;
      }
      var mitigationmethodidd = null;
      if (typeof this.editresolve.mitigationMethodId === "object") {
        mitigationmethodidd = this.editresolve.mitigationMethodId._id;
      } else {
        mitigationmethodidd = this.editresolve.mitigationMethodId;
      }
      var mitigationtypeidd = null;
      if (typeof this.mitigation === "object") {
        mitigationtypeidd = this.mitigation._id;
      } else {
        mitigationtypeidd = this.mitigation;
      }
      formData.append("lat", this.lattitude);
      formData.append("lon", this.longitude);
      formData.append("actualIncidentLocation", this.locationname);
      formData.append("actualIncidentTime", this.time);
      formData.append("actualIncidentDate", this.date);
      formData.append("animalId", this.animaltype);
      formData.append("animalActivityId", animalactivityidd);
      formData.append("sightingOrConflict", this.sightingorconflicttype);
      if (this.insideforest === "Yes") {
        formData.append("isInsideForest", true);
      }
      if (this.insideforest === "No") {
        formData.append("isInsideForest", false);
      }
      formData.append("RForPAName", this.reservedorprotectedname);
      if (this.animalpresent === "Yes") {
        formData.append("animalPresentOrNot", true);
      }
      if (this.animalpresent === "No") {
        formData.append("animalPresentOrNot", false);
      }

      if (this.editresolve.singleOrGroup === "Single") {
        formData.append("singleDetails", JSON.stringify(this.singleDetails));
      }
      if (this.editresolve.singleOrGroup === "Group") {
        formData.append("groupDetails", JSON.stringify(this.groupDetails));
      }
      formData.append("singleOrGroup", this.editresolve.singleOrGroup);
      formData.append("conflictTypeId", JSON.stringify(this.ConflictType));
      if (this.cropTypedetails) {
        formData.append(
          "cropDamageDetails",
          JSON.stringify(this.cropTypedetails)
        );
      }
      if (this.numberoflivestockdepredationanimal) {
        formData.append(
          "livestockAnimalCount",
          JSON.stringify(this.numberoflivestockdepredationanimal)
        );
      }
      if (
        this.resolvelattitude &&
        this.resolvelongitude &&
        this.editresolvedornot !== "Yes" &&
        this.editresolve.isStepsForMitigation !== true
      ) {
        formData.append("resolvedLat", this.resolvelattitude);
        formData.append("resolvedLon", this.resolvelongitude);
      }
      if (this.propertyTypedetails) {
        formData.append(
          "propertyDamageDetails",
          JSON.stringify(this.propertyTypedetails)
        );
      }
      if (this.injuredPeople) {
        formData.append(
          "humanInjuryDetails",
          JSON.stringify(this.formatHumanInjuryDetails())
        );
      }
      if (this.peopleDead) {
        formData.append(
          "humanDeathDetails",
          JSON.stringify(this.formathumanDeathDetails())
        );
      }
      if (this.animalInjuryDetails) {
        formData.append(
          "animalInjuryDetails",
          JSON.stringify(this.animalInjuryDetails)
        );
      }
      if (this.animalDeathDetails) {
        formData.append(
          "animalDeathDetails",
          JSON.stringify(this.animalDeathDetails)
        );
      }
      if (this.livestockanimal.length > 0) {
        formData.append(
          "livestockDepredationDetails",
          JSON.stringify(this.livestockanimal)
        );
      }
      if (this.landtype) {
        formData.append("landTypeId", JSON.stringify(landtypeidd));
      }
      if (this.breachbarrier === "Yes") {
        formData.append("isBreachedBarrier", true);
      }
      if (this.breachbarrier === "No") {
        formData.append("isBreachedBarrier", false);
      }
      if (this.barrierType.length > 0) {
        formData.append("barrierTypeId", JSON.stringify(this.barrierType));
      }
      if (this.breachType.length > 0) {
        formData.append("breachTypeId", JSON.stringify(this.breachType));
      }
      if (
        this.resolvedornot === "Yes" &&
        this.editresolve.isStepsForMitigation === false
      ) {
        formData.append("isResolved", true);
      }
      if (
        this.resolvedornot === "No" &&
        this.editresolve.isStepsForMitigation === false
      ) {
        formData.append("isResolved", false);
      }
      if (this.mitigation) {
        formData.append("mitigationTypeId", JSON.stringify(mitigationtypeidd));
      }
      if (this.editresolve.mitigationMethodId) {
        formData.append(
          "mitigationMethodId",
          JSON.stringify(mitigationmethodidd)
        );
      }
      if (this.farintoforest) {
        formData.append("howFarintoForest", JSON.stringify(this.farintoforest));
      }
      if (this.releasedornot === "Yes") {
        formData.append("isReleased", true);
      }
      if (this.releasedornot === "No") {
        formData.append("isReleased", false);
      }
      if (this.whynotreleased === "Treatment") {
        formData.append("isTreatment", true);
        formData.append(
          "treatmentDetails",
          JSON.stringify(this.formattreatmentDetails())
        );
      }
      if (this.whynotreleased === "Permanent") {
        formData.append("isPermanent", true);
        formData.append(
          "permanentDetails",
          JSON.stringify(this.formatpermenantDetails())
        );
      }
      if (
        this.resolvelocation &&
        !this.editresolvelocation &&
        this.editresolve.isStepsForMitigation === false
      ) {
        formData.append("resolvedLocationName", this.resolvelocation);
      }
      if (this.veterinarypresent === "Yes") {
        formData.append("isVeterinaryPresent", true);
        formData.append(
          "veterinaryRecommendations",
          this.veterinaryrecomendation
        );
      }
      if (this.veterinarypresent === "No") {
        formData.append("isVeterinaryPresent", false);
      }
      if (this.ifnotresolved === "Handing Over") {
        formData.append("isHandover", true);
      }

      if (
        this.ifnotresolved === "Steps taken for mitigation" &&
        this.editifnotresolved !== "Handing Over"
      ) {
        formData.append(
          "stepsForMitigation",
          JSON.stringify(this.mitigationSteps)
        );
        formData.append("isStepsForMitigation", true);
      }
      if (this.ifnotresolved === "Handing Over") {
        formData.append(
          "handoverDetails",
          JSON.stringify(this.handoverDetails)
        );
      }
      formData.append("resolveId", this.$route.query.id);
      for (let i = 0; i < this.docs.length; i++) {
        formData.append("resolveImages", this.docs[i]);
      }
      if (this.editresolvedornot === "Yes") {
        formData.append("isResolved", true);
      }
      if (this.editresolvedornot === "No") {
        formData.append("isResolved", false);
      }
      if (this.editmitigation) {
        formData.append(
          "mitigationTypeId",
          JSON.stringify(this.editmitigation)
        );
      }
      if (this.editmitigationmethods) {
        formData.append(
          "mitigationMethodId",
          JSON.stringify(this.editmitigationmethods)
        );
      }
      if (this.editfarintoforest) {
        formData.append(
          "howFarintoForest",
          JSON.stringify(this.editfarintoforest)
        );
      }
      if (this.editreleasedornot === "Yes") {
        formData.append("isReleased", true);
      }
      if (this.editreleasedornot === "No") {
        formData.append("isReleased", false);
      }
      if (this.editwhynotreleased === "Treatment") {
        formData.append("isTreatment", true);
        formData.append(
          "treatmentDetails",
          JSON.stringify(this.formatedittreatmentDetails())
        );
      }
      if (this.editwhynotreleased === "Permanent") {
        formData.append("isPermanent", true);
        formData.append(
          "permanentDetails",
          JSON.stringify(this.formateditpermenantDetails())
        );
      }
      if (this.editresolvedornot === "Yes" && this.editresolvelocation) {
        formData.append("resolvedLocationName", this.editresolvelocation);
        formData.append("resolvedLat", this.editresolvelattitude);
        formData.append("resolvedLon", this.editresolvelongitude);
      }
      if (
        this.editresolvelocation &&
        !this.resolvelocation &&
        this.editresolve.isStepsForMitigation === false
      ) {
        formData.append("resolvedLocationName", this.editresolvelocation);
      }
      if (this.editveterinarypresent === "Yes") {
        formData.append("isVeterinaryPresent", true);
        formData.append(
          "veterinaryRecommendations",
          this.editveterinaryrecomendation
        );
      }
      if (this.editveterinarypresent === "No") {
        formData.append("isVeterinaryPresent", false);
      }
      if (this.editifnotresolved === "Handing Over") {
        formData.append("isHandover", true);
        formData.append("isStepsForMitigation", true);
        formData.append(
          "stepsForMitigation",
          JSON.stringify(this.mitigationSteps)
        );
      }
      // if (this.editifnotresolved === "Steps taken for mitigation") {
      //   formData.append(
      //     "stepsForMitigation",
      //     JSON.stringify(this.editmitigationSteps)
      //   );
      //   formData.append("isStepsForMitigation", true);
      // }
      // if((this.editifnotresolved === "Steps taken for mitigation" || this.ifnotresolved === "Steps taken for mitigation") && (this.editifnotresolved !== "Handing Over" || this.ifnotresolved !== "Handing Over")){
      //   formData.append("isStepsForMitigation", true);
      // }
      if (this.editifnotresolved === "Handing Over") {
        formData.append(
          "handoverDetails",
          JSON.stringify(this.edithandoverDetails)
        );
      }
      if (this.removedImages.length > 0) {
        formData.append("removedImages", JSON.stringify(this.removedImages));
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/resolve/edit",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Case Updated Successfully!";
              this.showSnackBar = true;
              this.appLoading = false;
              // window.location.reload();
              // this.$router.push({
              //   path: "/admin/adminDashboard?id=assignedCases",
              // });
              this.$router
                .push({
                  path: "/admin/adminDashboard?id=assignedCases",
                })
                .then(() => {
                  window.location.reload();
                });
            }
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },
    getanimalData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animal/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.animalTypes = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getscenarioData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/scenario/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.sightingsorconflicts = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getanimalactivityData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animalActivity/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.ActivityofAnimals = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getconflicttypeData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/conflicttype/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.ConflictTypes = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getlandtypeData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/landType/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.TypeofLand = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getbarriertypeData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/barriertype/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.Typesofbarrier = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getbreachtypeData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/breachtype/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.Typesofbreach = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getmitigationtypeData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/mitigationType/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.MitigationTypes = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getmitigationmethodtypeData() {
      var value = null;
      if (typeof this.mitigation === "object") {
        value = this.mitigation._id;
      } else {
        value = this.mitigation;
      }
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/mitigationMethod/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          mitigationTypeId: value,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.MitigationmethodTypes = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    geteditmitigationmethodtypeData() {
      // var value = null;
      // if (typeof this.mitigation === "object") {
      //   value = this.mitigation._id;
      // } else {
      //   value = this.mitigation;
      // }
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/mitigationMethod/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          mitigationTypeId: this.editmitigation,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.MitigationmethodTypes = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getanimalageData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animalAge/view/",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.animaltype,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.ageofanimal = response.data.data.age;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getanimalstatusData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animalStatus/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.statusofanimal = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getanimalgenderData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/gender/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.genderofanimal = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editresolveplotLocation() {
      if (this.editresolvelocation) {
        this.geocoder.geocode(
          { address: this.editresolvelocation },
          (results, status) => {
            if (status === "OK") {
              const location = results[0].geometry.location;
              const lat = location.lat();
              const lng = location.lng();

              // Kerala latitude and longitude boundaries
              const isInKerala =
                lat >= 8.17 && lat <= 12.93 && lng >= 74.51 && lng <= 77.6;

              if (isInKerala) {
                this.editresolvelattitude = lat;
                this.editresolvelongitude = lng;
              } else {
                console.log(`Latitude: ${lat}, Longitude: ${lng}`);
                console.error("Location is outside Kerala.");
                this.msg = "Location must be within Kerala.";
                this.showSnackBar = true;
                this.editresolvelattitude = ""; // Reset latitude
                this.editresolvelongitude = ""; // Reset longitude
              }
            } else {
              console.error(
                "Geocode was not successful for the following reason: " + status
              );
              this.msg = "Unable to retrieve location. Please try again.";
              this.showSnackBar = true;
            }
          }
        );
      } else {
        this.msg = "Please enter a location name.";
        this.showSnackBar = true;
      }
    },
    resolveplotLocation() {
      if (this.resolvelocation) {
        this.geocoder.geocode(
          { address: this.resolvelocation },
          (results, status) => {
            if (status === "OK") {
              const location = results[0].geometry.location;
              const lat = location.lat();
              const lng = location.lng();

              // Kerala latitude and longitude boundaries
              const isInKerala =
                lat >= 8.17 && lat <= 12.93 && lng >= 74.51 && lng <= 77.6;

              if (isInKerala) {
                this.resolvelattitude = lat;
                this.resolvelongitude = lng;
              } else {
                console.log(`Latitude: ${lat}, Longitude: ${lng}`);
                console.error("Location is outside Kerala.");
                this.msg = "Location must be within Kerala.";
                this.showSnackBar = true;
                this.resolvelattitude = ""; // Reset latitude
                this.resolvelongitude = ""; // Reset longitude
              }
            } else {
              console.error(
                "Geocode was not successful for the following reason: " + status
              );
              this.msg = "Unable to retrieve location. Please try again.";
              this.showSnackBar = true;
            }
          }
        );
      } else {
        this.msg = "Please enter a location name.";
        this.showSnackBar = true;
      }
    },
    resolveresetFields() {
      // Clear the fields
      this.resolvelattitude = "";
      this.resolvelongitude = "";
      this.resolvelocation = "";
    },
    editresolveresetFields() {
      // Clear the fields
      this.editresolvelattitude = "";
      this.editresolvelongitude = "";
      this.editresolvelocation = "";
    },
    getcroptypeData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/crop/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.Typeofcrops = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getDivisionData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/platform/division/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.divisionList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRangeData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/range/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rangeList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRRTData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/rrt/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rrtList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    setCurrentTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      this.time = `${hours}:${minutes}`;
      this.timeofcapture = `${hours}:${minutes}`;
    },
    addMitigationStep() {
      this.mitigationSteps.push({
        name: "",
        mitigationStartDate: "",
        menu: false,
      });
    },
    addeditMitigationStep() {
      this.editmitigationSteps.push({
        name: "",
        mitigationStartDate: "",
        menu: false,
      });
    },
    addcropTypedetails() {
      this.cropTypedetails.push({
        cropId: "",
        count: "",
        unit: "",
      });
    },
    removecropTypedetails(index) {
      this.cropTypedetails.splice(index, 1);
    },
    removeMitigationStep(index) {
      this.mitigationSteps.splice(index, 1);
    },
    removeeditMitigationStep(index) {
      this.editmitigationSteps.splice(index, 1);
    },
    addpropertyTypedetails() {
      this.propertyTypedetails.push({
        propertyType: "",
        damageDetail: "",
      });
    },
    removepropertyTypedetails(index) {
      this.propertyTypedetails.splice(index, 1);
    },
    addlivestockanimaldetails() {
      this.livestockanimal.push({
        injuryOrDeath: "",
        numberOfAnimals: "",
        typeOfAnimal: "",
      });
    },
    removelivestockanimaldetails(index) {
      this.livestockanimal.splice(index, 1);
    },
  },
};
</script>
  <style>
.SubitemHeading1 {
  color: #000000;
  font-size: 20px;
  font-family: sofiaProBold;
}
.SubitemHeading2 {
  color: #000000;
  font-size: 16px;
  font-family: sofiaProBold;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
  